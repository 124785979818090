import { Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const EnachDebitStatus = (props) => {
  const [storeStatus, setStoreStatus] = useState(props);
  const fetchEnachDebitStatus = async () => {
    try {
      await axios
        .post(`enach_mandate/check_enach_recurring_transaction_api_status`, {
          OrderId: props?.OrderId,
        })
        .then((res) => {
          setStoreStatus(res.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchEnachDebitStatus();
  }, []);
  return (
    <Button
      sx={{
        width: "auto",
        maxWidth: "270px",
        height: "45px",
        fontWeight: storeStatus?.transaction_error_desc ? "bold" : "normal",
        color:
          storeStatus?.transaction_error_desc === "Transaction Pending"
            ? "#ffb703"
            : storeStatus?.transaction_error_desc === "Transaction Successful"
            ? "green"
            : "red",
        fontSize: "16px",
        width: "90%",
        lineHeight: "1.5",
        letterSpacing: "0.02em",
        textTransform: "none",
        borderRadius: "15px",
      }}
    >
      {storeStatus?.transaction_error_desc ?? "---"}
    </Button>
  );
};

export default EnachDebitStatus;
