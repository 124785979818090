import { Box, Button, Input, Typography, Divider } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function Tds_gst() {
  const [value, setValue] = useState("");
  const [valueForGst, setValueForGst] = useState("");
  const [error, setError] = useState("");
  const [errorforGst, setErrorforGst] = useState("");
  const [storeTDS, setStoreTDS] = useState("");
  const [storeUpdatedTDS, setStoreUpdatedTDS] = useState("");

  const handleInputChangeForTDS = (e, flag) => {
    const inputValue = e.target.value;
    if (flag) {
      inputValue === "0" ? setError("Cannot enter 0") : setError("");
      setStoreTDS(inputValue);
      setValue(inputValue);
    } else {
      const numericValue = Number(inputValue);
      numericValue > 100
        ? setErrorforGst("Cannot enter more than 100")
        : setErrorforGst("");
      setValueForGst(inputValue);
    }
  };

  const handleKeyPressForTDS = (e, flag) => {
    if (e.key === "-" || e.key === "e" || e.key === "E") e.preventDefault();
    flag && e.key === "-"
      ? setError("Cannot enter negative value")
      : setError("");
  };

  const Update_TDS = async () => {
    try {
      const res = await axios.post(`Update_TDS_GST/update_tds`, {
        tds: storeTDS,
      });
      if (res?.data?.updatedTDS?.TDS) {
        toast.success(`TDS update to ${res?.data?.updatedTDS?.TDS}%`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetch_tds = async () => {
    try {
      const response = await axios.get(`Update_TDS_GST/get_admin_tds`);
      setStoreUpdatedTDS(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetch_tds();
  }, []);

  const handlePaste = (e) => e.preventDefault();

  return (
    <Box sx={mainBox}>
      <Box sx={sectionBox}>
        <Typography sx={sectionTitle}>TDS</Typography>
        <Input
          sx={inputField}
          disableUnderline
          placeholder="Enter TDS Amount"
          type="number"
          value={value}
          onChange={(e) => handleInputChangeForTDS(e, true)}
          onKeyPress={(e) => handleKeyPressForTDS(e, true)}
          inputProps={{ min: 0.1 }}
          onPaste={handlePaste}
        />
        {error && <Typography sx={errorText}>{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          sx={actionButton}
          onClick={Update_TDS}
        >
          Update TDS
        </Button>
        <Divider sx={dividerStyle} />
        <Box sx={listStyle}>
          <Box sx={listItem}>
            <Typography>TDS: {storeUpdatedTDS?.TDSData?.TDS}%</Typography>
            <Typography>
              Last Update On:{" "}
              {new Date(storeUpdatedTDS?.TDSData?.updatedAt).toDateString()} at{" "}
              {new Date(
                storeUpdatedTDS?.TDSData?.updatedAt
              ).toLocaleTimeString()}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem sx={dividerStyle} />

      <Box sx={sectionBox}>
        <Typography sx={sectionTitle}>GST</Typography>
        <Input
          sx={inputField}
          disableUnderline
          placeholder="Enter GST Amount"
          type="number"
          value={valueForGst}
          onChange={(e) => handleInputChangeForTDS(e, false)}
          onKeyPress={(e) => handleKeyPressForTDS(e, false)}
          inputProps={{ min: 0 }}
          onPaste={handlePaste}
        />
        {errorforGst && <Typography sx={errorText}>{errorforGst}</Typography>}
        <Button variant="contained" color="primary" sx={actionButton}>
          Update GST
        </Button>
        <Divider sx={dividerStyle} />
        <Box sx={listStyle}>
          {[...Array(3)].map((_, ind) => (
            <Box key={ind} sx={listItem}>
              <Typography>{ind + 1}.</Typography>
              <Typography>TDS: 12%</Typography>
              <Typography>createdAt: 24/09/2024</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const mainBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "auto",

  width: "100%",
  padding: "20px",
  backgroundColor: "#f9f9f9",
  gap: "20px",
};

const sectionBox = {
  width: "40%",
  backgroundColor: "#fff",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

const sectionTitle = {
  fontSize: "24px",
  fontWeight: "600",
  fontFamily: "Poppins",
  color: "#333",
  textAlign: "center",
};

const inputField = {
  borderRadius: "8px",
  padding: "10px",
  width: "100%",
  backgroundColor: "#f0f0f0",
  fontFamily: "Poppins",
  fontSize: "14px",
};

const actionButton = {
  textTransform: "none",
  width: "100%",
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "#1976d2",
  ":hover": {
    backgroundColor: "#1565c0",
  },
};

const errorText = {
  color: "red",
  fontSize: "12px",
  textAlign: "center",
  marginTop: "-10px",
};

const listStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const listItem = {
  display: "grid",
  gap: "10px",
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#555",
};

const dividerStyle = {
  backgroundColor: "#e0e0e0",
  margin: "20px 0",
};
